ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    gap: 0px 10px;
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
    color: #8892b0;
}

.skills-list li::before {
    content: "▹";
    /* position: absolute; */
    left: 0px;
    color: #64ffda;
    /* background-color: "#64ffda"; */
    /* font-size: var(--fz-sm); */
    line-height: 12px;
   
}
.expirence ul li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: #64ffda;
}
.expirence ul {
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
    list-style: none;
    font-size: var(--fz-lg);
}
.expirence ul li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    color: #8892b0;
}
/* .expirence span::first-letter {
    margin-right: 2%;
    
   
} */
.cardRoot{
    background-color: #64ffda !important; 
    transform: translate(-12px, -14px);
}

.cardRoot:hover{
    transform: translate(-9px, -9px);
}
.card{
    color: #64ffda; 
    background-color: #64ffda;
    mix-blend-mode: multiply;
    filter: grayscale(100%) contrast(1);
}
.card:hover{
    box-shadow: -1px 10px 29px 0px rgba(0,0,0,0.8);
    /* background-color: #64ffda; */
    mix-blend-mode: unset;
    filter:none;
    transform: scale(1.01) translateY(0.2rem); 
    /* color: #64ffda; */
  }

  .card:active {
    transform: scale(1.01) translateY(0.2rem); 
    background-color: #64ffda;
    /* color: #64ffda; */
 } 
  .card  .img {
    position: relative;
    top: -25%;
    width: 100%;
    background-color: #64ffda;
    color: #64ffda;
    mix-blend-mode: multiply;
/* filter: grayscale(100%) contrast(1); */
  }
 
/* .card:n{
    background-color: #64ffda;
    color: #64ffda;  
  }
} */

/* .MuiTab-textColorPrimary{
    text-emphasis-color : #8892b0;
    /* textColor :  #8892b0;   */
    /* color:   #8892b0;   */
/* } */
/* .MuiTab-textColorSecondary{
    text-emphasis-color : #8892b0; 
    /* textColor :  #8892b0;  */
    /* color:   #8892b0;  */
/* } */ 

.MuiTab-root:hover{
    background-color: #0f2444;
    color:red;
} 
.MuiTab-root{
    /* background-color: #64ffda;; */
    color:#8892b0 !important;
} 
.MuiTab-root:active{
    background-color: #64ffda;;
    /* color:red; */
}
/* .tabs{
    background-color: red;
    /* color:red; */
/* } */ */

.MuiButtonBase-root-MuiTab-root.Mui-selected{
    background-color: green !important;
    /* color:red */
}
/* .Mui-selected:{
    background-color: green !important;
    color:red
} */
.MuiTab-root:active {
    color: #8892b0 ;
}
.Mui-selected {
   color: #64ffda !important;
   /* width: 200px; */
}
.MuiTabs-indicator {
    background-color: #64ffda !important;
}

/* //projectDetails CSS */

.projectTitle .MuiCardHeader-title{
    /* font-size: 20px !important; */
    font-weight: bolder;
}
.MuiCardHeader-title:hover{
    color:#64ffda 
    /* font-size: 28px !important;
    font-weight: bolder; */
}

.MuiCard-root{
    height: 100% !important;
}

.MuiCard-root:hover{
    transform: scale(1.01) translateY(-0.7rem); 
}



/* contactcSS */
.contactBtn{
border: 1px solid #64ffda !important;
/* background-color: #dff3ee !important; */
}
/* .contactBtn:hover{

    background-color: #b2f5e4 !important;
    opacity: 9%;
    border-color: '#0062cc';
    box-shadow: 'none';
} */

/* footer */
.footer:hover{
color:#64ffda
}

.sticky::after{
    content: "";
    display: block;
    position: relative;
    /* top: -5px; */
    /* width: 300px; */
    /* height: 1px; */
    /* margin-left: 20px; */
    margin-top: 5%;
    width: 1px;
    height: 150px;
    right: 10px;
    background-color: grey;
}


.horizontalLine1::after{
    content: "";
    display: block;
    position: relative;
    /* top: -5px; */
    /* width: 300px; */
    /* height: 1px; */
    /* margin-left: 20px; */
    left: 20px;
    margin-top: 4%;
    width: 1px;
    height: 150px;
    right: 10px;
    background-color: grey;
}
.horizontalLine1{
    display: flex;
    position: fixed;
    flex-direction: column;
    left: 40px;
    /* right: 40px; */
    bottom: 0;
    width: 100%;
    margin-right: 5;
    align-items: baseline;
}

.sticky{
    /* position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 5px; */
    /* align-items:flex-end; */
    display: flex;
    position: fixed;

    /* left: 70%; */
    right: 40px;
    bottom: 0;
    width: 100%;
    margin-right: 5;
    font-family: 'monaco, monospace' !important;
    width: 20px;
    /* background-color: red; */
    /* color: white; */
    /* text-align: center; */

}
/* content: "";
display: block;
width: 1px;
height: 90px;
margin: 0px auto; */

.sticky:hover{
    color:#64ffda
}

