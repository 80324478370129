.App {
  text-align: center;
  min-height: 100vh;
  background-color: #0a1930;
  height: 100%;
}
.kFCHyX .logo {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.kFCHyX .logo a svg {
  fill: none;
  transition: var(--transition);
  user-select: none;
}
#logo {
  color: var(--green);
}
svg {
  width: 100%;
  height: 100%;
  fill: currentcolor;
  vertical-align: middle;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiListItemText-primary{
  font-family: monaco, monospace !important
}